import Vue from 'vue'
import pageMixin from '../../mixins/page'

export default Vue.extend({
  name: 'XpErrorPage',
  mixins: [pageMixin],
  computed: {
    errorMessage () {
      if (!this.$xp.network.isOnline) {
        return this.$t('global.errorOffline')
      }
      if (this.pageData.Error) {
        if (this.pageData.Error.response) {
          switch (this.pageData.Error.response.status) {
            case 404:
              return this.$t('global.error404')
            case 500:
              return this.$t('global.error500')
          }
          if (process.env.NODE_ENV === 'development' && this.pageData.Error.response && this.pageData.Error.response.data && this.pageData.Error.response.data.message) {
            return this.pageData.Error.response.data.message
          }
        }
        if (process.env.NODE_ENV === 'development') return this.pageData.Error.message
      }
      return this.$t('global.errorUnknown')
    }
  },
  mounted () {
    document.title = this.$t('global.error')
  },
  watch: {
    '$xp.network.isOnline': {
      handler (newValue) {
        if (newValue) {
          this.$xp.http.retryLastFailedRoute()
        }
      }
    }
  },
  render (h) {
    return h(
      'div',
      {
        class: 'c-error-page'
      },
      [
        h('span', {
          staticClass: 'xp xp--huge',
          class: {
            'xp-no-wlan': !this.$xp.network.isOnline,
            'xp-connection-error': this.$xp.network.isOnline
          }
        }),
        h('p', {}, this.errorMessage)
      ])
  }
})
