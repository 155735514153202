import debug from 'debug'

const log = debug('xpedeo-core:nfc')
debug.enable(process.env.VUE_APP_DEBUG)

export default {
  __isEnabled: false,
  __$xp: null,
  __router: null,
  __isListenersAdded: false,
  __branches: [],
  __nfcCode: null,
  __selectedLangCode: null,
  __nfcCodeFound: false,
  __selectedBranchIds: [],
  __lastNfcRoute: null,
  __nfcData: null,
  get nfcData () {
    return this.__nfcData
  },
  set nfcData (newValue) {
    this.__nfcData = newValue
  },
  get lastNfcRoute () {
    return this.__lastNfcRoute
  },
  set lastNfcRoute (newValue) {
    this.__lastNfcRoute = newValue
  },
  get selectedBranchIds () {
    return this.__selectedBranchIds
  },
  set selectedBranchIds (newValue) {
    this.__selectedBranchIds = newValue
  },
  get nfcCodeFound () {
    return this.__nfcCodeFound
  },
  set nfcCodeFound (newValue) {
    this.__nfcCodeFound = newValue
  },
  get branches () {
    return this.__branches
  },
  set branches (newValue) {
    this.__branches = newValue
  },
  get selectedLangCode () {
    return this.__selectedLangCode
  },
  set selectedLangCode (newValue) {
    this.__selectedLangCode = newValue
  },
  get nfcCode () {
    return this.__nfcCode
  },
  set nfcCode (newValue) {
    this.__nfcCode = newValue
  },
  get isEnabled () {
    return this.__isEnabled
  },
  enable () {
    log('nfc.enable()')
    if (this.__$xp.device.platform === 'browser') {
      log('nfc.enable(): nfc is not available in browser')
      return
    }
    if (window.nfc) {
      window.nfc.enabled(success => {
        log(`Nfc is enabled, ${success}`)
        if (this.__$xp.device.platform === 'android') {
          if (this.__isListenersAdded) {
            log('listeners are already added')
            this.__isEnabled = true
            return
          }
          window.nfc.addNdefListener(this.onNfcNdef.bind(this), () => { this.__isEnabled = true }, () => { log('Error in nfc.addNdefListener') })
          this.__isListenersAdded = true
        } else this.__isEnabled = true
      }, failure => {
        log(`Failed to enable Nfc, ${failure}`)
      })
    } else {
      log('NFC plugin is missing!!')
    }
  },
  disable () {
    log('nfc.disable()')
    if (this.__$xp.device.platform === 'browser') {
      log('nfc.disable(): nfc is not available in browser')
    } else if (this.__$xp.device.platform === 'android') {
      this.__isEnabled = false
    } else if (this.__$xp.device.platform === 'ios' && window.nfc) {
      this.__isEnabled = false
      window.nfc.cancelScan().then(
        success => {
          log(`Cancelled NFC session ${success}`)
          this.__isEnabled = false
        },
        err => {
          log(`Error cancelling session ${err}`)
          this.__isEnabled = undefined
        }
      )
    }
    /**
     * see https://github.com/chariotsolutions/phonegap-nfc#nfcremovendeflistener
     * Removing listeners is not recommended. Instead, consider that your callback can ignore messages you no longer need.
     */
    // window.nfc.removeNdefListener(this.onNfcNdef.bind(this), () => { this.__isEnabled = false }, () => { log('Error in nfc.removeNdefListener') })
    // if (this.__$xp.device.platform === 'ios') {
    //   window.nfc.invalidateSession(() => {}, () => { log('Error in nfc.invalidateSession') }) // eslint-disable-line
    // }
  },
  async onNfcNdef (nfcEvent) {
    if (!this.isEnabled) {
      return
    }
    const tag = nfcEvent.tag
    log('onNfcNdef: ' + JSON.stringify(tag), this.__$xp)
    if (tag.ndefMessage && tag.ndefMessage.length) {
      for (const i in tag.ndefMessage) {
        const message = tag.ndefMessage[i]
        const payload = window.nfc.bytesToString(message.payload)
        log('onNfcNdef: payload = ' + payload)
        const match = payload.match(/\/#\/nfc\/(.+)$/)
        if (match) {
          const code = match[1]
          let pageId = window.localStorage.getItem('nfcPageId') || this.__$xp.settings.mergedSettings.nfcPageId || this.__$xp.settings.mergedSettings.content.nfcPageId
          log('onNfcNdef: pageId = ' + pageId)
          if (!pageId) {
            pageId = this.__$xp.settings.getValue('content.startPageID')
            log('onNfcNdef: pageId not found, using startPageID = ' + pageId)
          }
          // const path = `/page/${pageId}?nfcCode=${code}`
          this.nfcCode = code
          this.lastNfcRoute = pageId
          log('onNfcNdef: lastNfcRoute = ' + this.lastNfcRoute)
          await this.startNfcNavigation()
          // if (this.__router.currentRoute && this.__router.currentRoute.path.includes(`/page/${pageId}`)) {
          //   log('in onNfcNdef, replacing path')
          //   this.nfcCode = code
          //   // this.__router.replace({ path: path })
          // } else {
          //   log('in onNfcNdef, pushing path')
          //   // this.__router.push({ path: path })
          // }
          // log(`router.push: path=${path}`)
          break
        }
      }
    }
  },
  scanNdefIos () {
    if (this.__$xp.device.platform === 'ios' && this.isEnabled) {
      window.nfc.scanNdef().then(
        iTag => {
          log(`in scanNdefIos ${iTag}`)
          this.onNfcNdef({ tag: iTag })
          return true
        },
        err => {
          log('Error scanning tag on iOS', err)
          return false
        }
      )
    }
  },
  async startNfcNavigation () {
    log(this, this.__router)
    if ((this.__router.currentRoute && this.__router.currentRoute.query.nfcCode) || this.nfcCode) {
      // log('nfcCode is defined, code: ', this.$route.query.nfcCode)
      // this.nfcCode = this.__router.currentRoute.query.nfcCode
      if (this.__router.currentRoute && this.__router.currentRoute.query.nfcCode) {
        this.nfcCode = this.__router.currentRoute.query.nfcCode
      } else
      if (typeof this.nfcCode === 'string' && parseInt(this.nfcCode) > 0 && parseInt(this.nfcCode).toString() === this.nfcCode) {
        log(this.nfcCode)
        log('parsing nfcCode')
        this.nfcCode = parseInt(this.nfcCode)
      }
      log('should build branches')
      await this.buildBranches()
    } else {
      log('this.$route.query.nfcCode undefined', this.$route)
    }
  },
  setAppLang (lang) {
    window.localStorage.setItem('nfcLangCode', lang.langCode)
    this.$xp.polyglot.setLanguage(lang.langCode, lang.path)
    this.selectedLangCode = lang.langCode
    this.__router.push('/' + lang.path + 'page/' + this.pageData.ID + '?nfcCode=' + this.$route.query.nfcCode)
  },
  goToPage (item) {
    log('goToPage', item)
    const selectedBranchIds = []
    let p = item
    while (p.ancestorId) {
      selectedBranchIds.push(p.ancestorId)
      p = this.nfcData.ancestors[p.ancestorId]
    }
    window.localStorage.setItem('nfcSelectedBranchIds', selectedBranchIds)
    this.__router.push('/page/' + item.id)
  },
  async buildBranches () {
    const pageId = window.localStorage.getItem('nfcPageId') || this.__$xp.settings.mergedSettings.nfcPageId || this.__$xp.settings.mergedSettings.content.nfcPageId
    this.lastNfcRoute = pageId

    const PageData = await this.__$xp.http.getPageData(pageId)

    this.nfcData = PageData.nfc

    this.branches = []
    this.selectedBranchIds = []
    if (this.nfcData && this.nfcData.pages) {
      if (this.nfcData.pages[this.nfcCode]) {
        if (this.nfcData.pages[this.nfcCode].length === 1 && this.nfcData.autoStart) {
          this.goToPage(this.nfcData.pages[this.nfcCode][0])
        } else {
          this.nfcData.pages[this.nfcCode].forEach((item) => {
            this.nfcCodeFound = true
            this.addBranchItem(item)
          })
          if (this.__router.currentRoute && this.__router.currentRoute.path.includes(`/page/${pageId}`)) {
            log('in buildBranches, replacing path', this.lastNfcRoute)
            this.__router.replace('/page/' + this.lastNfcRoute + '?nfcCode=' + this.nfcCode)
          } else {
            log('in buildBranches, pushing path', this.lastNfcRoute)
            this.__router.push('/page/' + this.lastNfcRoute + '?nfcCode=' + this.nfcCode)
          }
        }
      }
    }
  },
  addBranchItem (item) {
    item = Object.assign({}, item)
    if (!item.children) {
      item.children = []
    }
    if (item.ancestorId) {
      const found = this.branches.find(function (x) {
        return x.id === item.ancestorId
      })

      if (found) {
        found.children.push(item)
      } else if (this.nfcData.ancestors[item.ancestorId]) {
        const ancestor = this.nfcData.ancestors[item.ancestorId]
        const added = this.addBranchItem(ancestor)
        added.children.push(item)
      }
    } else {
      this.branches.push(item)
    }
    return item
  },
  resetState () {
    this.branches = []
    this.nfcCode = null
    this.selectedLangCode = null
    this.nfcCodeFound = false
    this.selectedBranchIds = []
    this.lastNfcRoute = null
    this.nfcData = null
  },
  install (Vue, opts, $xp) {
    log('Installing NFC Plugin')
    const router = opts.router
    if ($xp.nfcInstalled) { return }
    if (!opts.router) {
      throw new Error('NFC plugin needs router to initialize')
    }
    // to-do test in a late if nfc plugin is available
    // if (window.nfc) {
    //   log('NFC plugin is present.')
    // } else if ($xp.device.platform !== 'browser') {
    //   throw new Error('NFC plugin is missing!!')
    // }
    this.__$xp = $xp
    this.__router = router
    this.__router.addRoutes([
      {
        path: '/nfc/:nfcCode',
        redirect: to => {
          let pageId = window.localStorage.getItem('nfcPageId') || $xp.settings.getValue('content.nfcPageId')
          if (!pageId) {
            pageId = $xp.settings.getValue('content.startPageID')
          }
          let langUrlPath = $xp.polyglot.urlPath
          const localStorageLangCode = window.localStorage.getItem('nfcLangCode')

          if (localStorageLangCode && localStorageLangCode !== $xp.polyglot.locale) {
            for (const lang of $xp.settings.mergedSettings.content.languages) {
              if (lang.langCode === localStorageLangCode) {
                langUrlPath = lang.path
                break
              }
            }
          }
          return { path: `/${langUrlPath}page/${pageId}`, query: { nfcCode: to.params.nfcCode } }
        }
      }
    ])
    $xp.nfcInstalled = true
    Vue.util.defineReactive($xp, 'nfc', this)
  }
}
