const preventSleep = function () {
  return new Promise(function (resolve, reject) {
    if (process.env.VUE_APP_IS_LOAN_DEVICE) {
      window.XpedeoPreloader.preventSleep(
        function () {
          resolve()
        },
        (errMsg) => {
          reject(new Error(errMsg))
        })
    } else resolve()
  })
}

const allowSleep = function () {
  return new Promise(function (resolve, reject) {
    if (process.env.VUE_APP_IS_LOAN_DEVICE) {
      window.XpedeoPreloader.allowSleep(
        function () {
          resolve()
        },
        (errMsg) => {
          reject(new Error(errMsg))
        })
    } else resolve()
  })
}

const setBrightness = function (brightness) {
  return new Promise(function (resolve, reject) {
    if (process.env.VUE_APP_IS_LOAN_DEVICE) {
      window.XpedeoPreloader.setScreenBrightness(
        brightness ? [brightness] : [],
        function () {
          resolve()
        },
        (errMsg) => {
          reject(new Error(errMsg))
        })
    } else resolve(brightness)
  })
}

const setOrientation = function (orientation) {
  return new Promise(function (resolve, reject) {
    if (process.env.VUE_APP_IS_LOAN_DEVICE) {
      window.XpedeoPreloader.setScreenOrientation(
        orientation ? [orientation] : [],
        function () {
          resolve()
        },
        (errMsg) => {
          reject(new Error(errMsg))
        })
    } else if (['ios', 'android'].includes(process.env.VUE_APP_PLATFORM)) {
      orientation ? window.screen.orientation.lock(orientation) : window.screen.orientation.unlock()
      resolve()
    }
  })
}

const getOrientation = function () {
  return window.screen.orientation
}

export default {
  preventSleep,
  allowSleep,
  setBrightness,
  setOrientation,
  getOrientation
}
