const goToAdminActivity = function () {
  return new Promise(function (resolve, reject) {
    if (process.env.VUE_APP_IS_LOAN_DEVICE) {
      window.XpedeoPreloader.goToAdminActivity(
        () => {
          resolve()
        },
        (errMsg) => {
          reject(new Error(errMsg))
        })
    }
  })
}

const getScheduledPreloadDate = function () {
  return new Promise(function (resolve, reject) {
    if (process.env.VUE_APP_IS_LOAN_DEVICE) {
      window.XpedeoPreloader.getScheduledPreloadDate(
        (date) => {
          resolve(date)
        },
        (errMsg) => {
          reject(new Error(errMsg))
        })
    }
  })
}

const getPreloadLogFile = function () {
  return new Promise(function (resolve, reject) {
    if (process.env.VUE_APP_IS_LOAN_DEVICE) {
      window.XpedeoPreloader.getPreloadLogFile(
        (filePath) => {
          resolve(filePath)
        },
        (errMsg) => {
          reject(new Error(errMsg))
        })
    }
  })
}

const getDeviceTimeOffset = function () {
  return new Promise(function (resolve, reject) {
    if (process.env.VUE_APP_IS_LOAN_DEVICE) {
      window.XpedeoPreloader.getTimeOffset(
        (offset) => {
          resolve(offset)
        },
        (errMsg) => {
          reject(new Error(errMsg))
        })
    }
  })
}

const getRemappedUrl = function (url) {
  return new Promise(function (resolve, reject) {
    if (process.env.VUE_APP_IS_LOAN_DEVICE || (window.XpedeoPreloader && process.env.VUE_APP_PLATFORM.toLowerCase() === 'android')) {
    // if (window.device.platform !== 'browser' && window.device.platform !== 'ios') {
      window.XpedeoPreloader.getRemappedUrl(
        url,
        (remappedUrl) => {
          resolve(remappedUrl)
        },
        (errMsg) => {
          reject(new Error(errMsg))
        })
    } else {
      resolve(url)
    }
  })
}

const checkPermissions = function (permissions = []) {
  return new Promise(function (resolve, reject) {
    console.log('checkPermissions', process.env.VUE_APP_PLATFORM.toLowerCase())
    if (process.env.VUE_APP_PLATFORM.toLowerCase() === 'android') {
      const diagnostic = window.cordova.plugins.diagnostic

      if (process.env.VUE_APP_IS_LOAN_DEVICE) {
        permissions = [
          diagnostic.permission.WRITE_EXTERNAL_STORAGE,
          diagnostic.permission.READ_EXTERNAL_STORAGE,
          diagnostic.permission.ACCESS_FINE_LOCATION,
          diagnostic.permission.ACCESS_COARSE_LOCATION
        ]
        if (window.XpedeoAR) {
          permissions.push(diagnostic.permission.CAMERA)
        }
      } else {
        permissions = permissions.map(item => diagnostic.permission[item])
        // const addPermissions = [
        //   diagnostic.permission.WRITE_EXTERNAL_STORAGE,
        //   diagnostic.permission.READ_EXTERNAL_STORAGE
        // ]
        // addPermissions.forEach(element => {
        //   if (!(element in permissions)) {
        //     permissions.push(element)
        //   }
        // })
      }
      console.log('Checking permissions', permissions)
      permissions.length === 0 && resolve()
      permissions.length && diagnostic.requestRuntimePermissions(
        function (statuses) {
          let grantedCounter = 0
          for (const permission in statuses) {
            switch (statuses[permission]) {
              case diagnostic.permissionStatus.GRANTED:
                console.log('Permission granted to use ' + permission)
                grantedCounter += 1
                break
              case diagnostic.permissionStatus.NOT_REQUESTED:
                console.log('Permission to use ' + permission + ' has not been requested yet')
                break
              case diagnostic.permissionStatus.DENIED:
                console.log('Permission denied to use ' + permission + ' - ask again?')
                break
              case diagnostic.permissionStatus.DENIED_ALWAYS:
                console.log('Permission permanently denied to use ' + permission + ' - guess we won\'t be using it then!')
                break
            }
          }
          if (grantedCounter === Object.keys(statuses).length) {
            resolve(true)
          } else {
            reject(new Error(`Permissions were not granted. ${Object.keys(statuses).length} were requested but ${grantedCounter} were granted.`))
          }
        },
        function (error) {
          reject(new Error(error))
        },
        permissions)
    } else {
      resolve(false)
    }
  })
}

const checkLoanLocationSettings = function () {
  return new Promise(function (resolve, reject) {
    if (process.env.VUE_APP_IS_LOAN_DEVICE) {
      window.XpedeoPreloader.checkLocationSettings(
        () => {
          resolve()
        },
        () => {
          reject(new Error('location settings are not valid'))
        }
      )
    } else {
      resolve()
    }
  })
}

const writeEvaRecord = function (jsonString) {
  return new Promise(function (resolve, reject) {
    if (process.env.VUE_APP_PLATFORM.toLowerCase() === 'android') {
      window.XpedeoPreloader.writeEvaRecord(
        jsonString ? [jsonString] : [],
        () => {
          console.log('mediaRecord Written')
          resolve()
        },
        () => {
          reject(new Error('Evaluation record not written'))
        }
      )
    } else {
      resolve()
    }
  })
}

export {
  goToAdminActivity,
  getScheduledPreloadDate,
  getPreloadLogFile,
  getDeviceTimeOffset,
  getRemappedUrl,
  checkPermissions,
  checkLoanLocationSettings,
  writeEvaRecord
}
