const lockWifiOn = function (lockId) {
  return new Promise(function (resolve, reject) {
    if (process.env.VUE_APP_IS_LOAN_DEVICE) {
      window.XpedeoPreloader.lockWifiOn(
        lockId,
        function (locks) {
          resolve(locks)
        },
        (errMsg) => {
          reject(new Error(errMsg))
        })
    } else {
      resolve([])
    }
  })
}

const releaseWifiOn = function (lockId) {
  return new Promise(function (resolve, reject) {
    if (process.env.VUE_APP_IS_LOAN_DEVICE) {
      window.XpedeoPreloader.releaseWifiOn(
        lockId,
        (locks) => {
          resolve(locks)
        },
        (errMsg) => {
          reject(new Error(errMsg))
        })
    } else {
      resolve([])
    }
  })
}

const lockWifiConnectionOn = function (lockId) {
  return new Promise(function (resolve, reject) {
    if (process.env.VUE_APP_IS_LOAN_DEVICE) {
      window.XpedeoPreloader.lockWifiConnectionOn(
        lockId,
        (locks) => {
          resolve(locks)
        },
        (errMsg) => {
          reject(new Error(errMsg))
        })
    } else {
      resolve([])
    }
  })
}

const releaseWifiConnectionOn = function (lockId) {
  return new Promise(function (resolve, reject) {
    if (process.env.VUE_APP_IS_LOAN_DEVICE) {
      window.XpedeoPreloader.releaseWifiConnectionOn(
        lockId,
        (locks) => {
          resolve(locks)
        },
        (errMsg) => {
          reject(new Error(errMsg))
        })
    } else {
      resolve([])
    }
  })
}

const isWifiEnabled = function () {
  return new Promise(function (resolve, reject) {
    if (process.env.VUE_APP_IS_LOAN_DEVICE) {
      window.XpedeoPreloader.isWifiEnabled(
        function () {
          resolve()
        },
        (errMsg) => {
          reject(new Error(errMsg))
        })
    } else {
      resolve()
    }
  })
}

const getConnectedSSID = function () {
  return new Promise(function (resolve, reject) {
    if (process.env.VUE_APP_IS_LOAN_DEVICE) {
      window.XpedeoPreloader.getConnectedSSID(
        (ssid) => {
          resolve(ssid)
        },
        (errorMsg) => {
          reject(new Error(errorMsg))
        }
      )
    } else {
      resolve()
    }
  })
}

export default {
  lockWifiOn,
  releaseWifiOn,
  lockWifiConnectionOn,
  releaseWifiConnectionOn,
  isWifiEnabled,
  getConnectedSSID
}
