import debug from 'debug'

const log = debug('xpedeo-core:cart')
debug.enable(process.env.VUE_APP_DEBUG)

export default {
  __pages: [],
  isInCart (pageId) {
    // log(`isInCart(${pageId})`)
    pageId = parseInt(pageId)
    if (this.__pages) {
      return this.__pages.some(function (page) {
        return page.id === pageId
      })
    } else {
      return false
    }
  },
  addToCart (pageId, pagePath, pageTitle) {
    // log(`addToCart(${pageId}, ${pagePath}, ${pageTitle})`)
    pageId = parseInt(pageId)

    if (!this.__pages) {
      this.__pages = []
    }

    if (!this.isInCart(pageId)) {
      this.__pages.push({
        id: pageId,
        path: pagePath,
        title: pageTitle
      })
      this.saveCart()
    }
    // log({ __pages: this.__pages })
  },
  removeFromCart (pageId) {
    pageId = parseInt(pageId)

    if (this.__pages && this.isInCart(pageId)) {
      for (let i = this.__pages.length - 1; i >= 0; i--) {
        if (this.__pages[i].id === pageId) {
          this.__pages.splice(i, 1)
        }
      }
      this.saveCart()
    }
  },
  saveCart () {
    log('Saving Cart')
    window.localStorage.setItem('cart', JSON.stringify(this.__pages))
  },
  restoreCart () {
    log('Restoring cart')
    this.__pages = JSON.parse(window.localStorage.getItem('cart'))
    // window.localStorage.setItem('cart', '')
  },
  install (Vue, opts, $xp) {
    if ($xp.cartInstalled) { return }
    $xp.cartInstalled = true
    Vue.util.defineReactive($xp, 'cart', this)
  }
}
