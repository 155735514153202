import deepmerge from 'deepmerge'
import settingsFacade from '../util/facades/settings-facade'
import lodashGet from 'lodash/get'
import defaultSettings from './default-settings'
import debug from 'debug'

const log = debug('xpedeo-core:settings')
debug.enable(process.env.VUE_APP_DEBUG)

export default {
  __loadedSettings: defaultSettings,
  __pageSettingsArray: [],
  __mergedSettings: {},
  __$xp: null,

  get pageSettingsArray () {
    return this.__pageSettingsArray
  },
  set pageSettingsArray (value) {
    const mergedPageSettings = (value.length >= 2) ? deepmerge.all(Object.values(value)) : value.length === 1 ? value[0] : {}
    this.__mergedSettings = deepmerge(Object.assign({}, this.__loadedSettings), mergedPageSettings)
    this.__pageSettingsArray = value
  },

  get mergedSettings () {
    return this.__mergedSettings
  },

  get remoteUrl () {
    return lodashGet(this.mergedSettings, 'content.remoteUrl') ? lodashGet(this.mergedSettings, 'content.remoteUrl') : ''
  },

  get baseUrl () {
    if (this.remoteUrl === '') {
      return window.XPEDEO_SETTINGS.content.remoteUrl
    } else {
      return this.mergedSettings.content.remoteUrl
    }
  },

  get useStaticPageFiles () {
    if (window.XPEDEO_SETTINGS && window.XPEDEO_SETTINGS.content && typeof (window.XPEDEO_SETTINGS.content.useStaticPageFiles) !== 'undefined') {
      return window.XPEDEO_SETTINGS.content.useStaticPageFiles
    } else {
      return this.mergedSettings.content.useStaticPageFiles
    }
  },

  getValue (valuePath, defaultValue = undefined) {
    return lodashGet(this.mergedSettings, valuePath, defaultValue)
  },
  saveSettingsState () {
    log('Saving settings state')
    window.localStorage.setItem('pageSettingsArray', JSON.stringify(this.__pageSettingsArray))
    window.localStorage.setItem('mergedSettings', JSON.stringify(this.__mergedSettings))
  },
  restoreSettingsState () {
    log('Restoring settings state')
    this.__pageSettingsArray = JSON.parse(window.localStorage.getItem('pageSettingsArray'))
    window.localStorage.setItem('pageSettingsArray', '')
    this.__mergedSettings = JSON.parse(window.localStorage.getItem('mergedSettings'))
    window.localStorage.setItem('mergedSettings', '')
  },
  resetSettingsState () {
    this.pageSettingsArray = []
  },

  async initSettings (langPath = null) {
    log('initSettings', langPath)
    let settings = await settingsFacade.getSettings(langPath)
    if (langPath !== null) {
      log('settings init should reset settings ', langPath)
      this.__loadedSettings = defaultSettings
    }

    if (settings.theme) {
      settings.theme = this.__$xp.device.isLoan && settings.loanTheme ? settings.loanTheme : (this.__$xp.device.platform === 'android' || this.__$xp.device.platform === 'ios') && settings.mobileTheme ? settings.mobileTheme : settings.theme ? settings.theme : window.XPEDEO_SETTINGS.theme || 't-clear'
      delete window.XPEDEO_SETTINGS.theme
    }
    if (!this.__$xp.device.isLoan && window.XPEDEO_SETTINGS) {
      settings = deepmerge(settings, window.XPEDEO_SETTINGS)
    }
    this.__loadedSettings = deepmerge(this.__loadedSettings, settings)
    this.pageSettingsArray = []
    log('settings initialized successfully.', this.__loadedSettings)

    if (!this.__$xp.device.isLoan || settings.allowHardwareVolumeControl) {
      this.__$xp.media.setGlobalVolume(1)
      this.__$xp.media.setAllowUIVolumeControl(false)
    }
  },
  async reloadSettings (langPath = null) {
    console.log('reloadSettings', langPath)
    let settings = await settingsFacade.getSettings(langPath)
    if (!this.__$xp.device.isLoan && window.XPEDEO_SETTINGS) {
      settings = deepmerge(settings, window.XPEDEO_SETTINGS)
    }
    this.__loadedSettings = deepmerge(defaultSettings, settings)
    log('settings reloaded successfully.', this.__loadedSettings)
    // eslint-disable-next-line no-self-assign
    this.pageSettingsArray = this.pageSettingsArray
    if (!this.__$xp.device.isLoan || settings.allowHardwareVolumeControl) {
      this.__$xp.media.setGlobalVolume(1)
      this.__$xp.media.setAllowUIVolumeControl(false)
    }
  },
  addPageSettings (pageId, pageSettings) {
    // @TODO xpedeo-core there could be a performance enhancement here.
    // could the array be mutated?
    log('addPageSettings', { pageId, pageSettings })
    const newPageSettingsArray = []
    this.__$xp.history.historyArray.forEach((item, index) => {
      if (item.pageId === pageId) {
        newPageSettingsArray[index] = pageSettings
      } else {
        if (this.pageSettingsArray[index]) {
          newPageSettingsArray[index] = this.pageSettingsArray[index]
        }
      }
    })
    this.pageSettingsArray = newPageSettingsArray
    log('addPageSettings success', this.pageSettingsArray)
  },

  install (Vue, opts, $xp) {
    if ($xp.settingsInstalled) { return }
    this.__$xp = $xp
    $xp.settingsInstalled = true
    this.__mergedSettings = Object.assign({}, defaultSettings)
    Vue.util.defineReactive($xp, 'settings', this)
  }
}
