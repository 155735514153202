import debug from 'debug'

const log = debug('xpedeo-core:clientsettings')
debug.enable(process.env.VUE_APP_DEBUG)

export default {
  __available: ['fontsize', 'outlines'],
  __fontsizes: [100, 150, 200],
  __$xp: null,
  get available () {
    return this.__available
  },
  get fontsizes () {
    return this.__fontsizes
  },
  saveClientSetting (name, value) {
    log('Saving ClientSetting', name, value)
    window.localStorage.setItem('clientsettings-' + name, value)
  },
  getClientSetting (name) {
    log('Get ClientSetting', name)
    return window.localStorage.getItem('clientsettings-' + name)
  },
  removeClientSetting (name) {
    log('Remove ClientSetting', name)
    return window.localStorage.removeItem('clientsettings-' + name)
  },
  applyClientSettings (aSelected) {
    log('Apply ClientSettings', aSelected)
    for (const cSetting of this.available) {
      if ((!aSelected || aSelected.some(cSetting)) && cSetting === 'fontsize') {
        if (this.getClientSetting('fontsize')) {
          for (const cSize of this.fontsizes) {
            document.querySelector('html').classList.remove('fontsize' + cSize)
          }
          document.querySelector('html').classList.add('fontsize' + this.getClientSetting('fontsize'))
        }
      }
      if ((!aSelected || aSelected.some(cSetting)) && cSetting === 'outlines') {
        if (this.getClientSetting('outlines')) {
          document.querySelector('html').classList.add('outlines')
        } else {
          document.querySelector('html').classList.remove('outlines')
        }
      }
    }
  },
  initEvents () {
    window.addEventListener('keyup', (e) => {
      console.log('ClientSettings KEY CHECK', e)
      const nKey = e.key
      switch (nKey) {
        case 'Tab':
          if (!parseInt(this.__$xp.clientsettings.getClientSetting('outlines'))) {
            console.log('Enabling OUTLINES')
            this.__$xp.clientsettings.saveClientSetting('outlines', 1)
            this.__$xp.clientsettings.applyClientSettings()
          }
          break
      }
    })
  },
  install (Vue, opts, $xp) {
    if ($xp.clientsettingsInstalled) { return }
    $xp.clientsettingsInstalled = true
    this.__$xp = $xp
    this.initEvents()
    Vue.util.defineReactive($xp, 'clientsettings', this)
  }
}
