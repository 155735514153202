/**
 * Throttling enforces a maximum number of times a function can be called over time. As in “execute this function at most once every X milliseconds.”.
 * see http://demo.nimius.net/debounce_throttle/
 *
 * @param {function} fn throttled function
 * @param {integer} limit in milliseconds
 */
export default function (fn, limit = 200) {
  let wait = false
  let result

  return function (/* ...args */) {
    if (wait === false) {
      wait = true
      setTimeout(() => { wait = false }, limit)
      result = fn.apply(this, arguments) // eslint-disable-line
    }

    return result
  }
}
