import wifiFacad from '../util/facades/wifi-facades'
import debug from 'debug'

const log = debug('xpedeo-core:wifi')
debug.enable(process.env.VUE_APP_DEBUG)

export default {
  __locks: [],
  get locks () {
    return this.__locks
  },
  async lockWifiOn (callerLockId) {
    log(`lockWifiOn(${callerLockId})`)
    try {
      this.__locks = await wifiFacad.lockWifiOn(callerLockId)
    } catch (e) {
      log('lockWifiOn error', e)
    }
  },

  async releaseWifiOn (callerLockId) {
    log(`releaseWifiOn(${callerLockId})`)
    try {
      this.__locks = await wifiFacad.releaseWifiOn(callerLockId)
    } catch (e) {
      log('releaseWifiOn error', e)
    }
  },

  async lockWifiConnectionOn (callerLockId) {
    log(`lockWifiConnectionOn(${callerLockId})`)
    try {
      this.__locks = await wifiFacad.lockWifiConnectionOn(callerLockId)
    } catch (e) {
      log('lockWifiConnectionOn error', e)
    }
  },

  async releaseWifiConnectionOn (callerLockId) {
    log(`releaseWifiConnectionOn(${callerLockId})`)
    try {
      this.__locks = await wifiFacad.releaseWifiConnectionOn(callerLockId)
    } catch (e) {
      log('releaseWifiConnectionOn error', e)
    }
  },

  async isWifiEnabled () {
    log('isWifiEnabled()')
    try {
      const isEnabled = await wifiFacad.isWifiEnabled()
      log('isWifiEnabled() success')
      return isEnabled
    } catch (e) {
      log('releaseWifiConnectionOn error', e)
    }
  },

  waitForWifi (timeoutMilliseconds = 10000) {
    log(`waitForWifi(${timeoutMilliseconds})`)
    return new Promise((resolve, reject) => {
      if (this.isWifiEnabled) {
        resolve()
      } else {
        let interval = null
        const timeout = setTimeout(() => {
          clearInterval(interval)
          reject(new Error('wait for wifi timeout.'))
        }, timeoutMilliseconds)
        interval = setInterval(() => {
          if (this.isWifiEnabled) {
            clearTimeout(timeout)
            resolve()
          }
        }, 1000)
      }
    })
  },

  async getConnectedSSID () {
    log('getConnectedSSID')
    try {
      const ssid = await wifiFacad.getConnectedSSID()
      log('getConnectedSSID() success')
      return ssid
    } catch (e) {
      log('releaseWifiConnectionOn error', e)
    }
  },

  install (Vue, opts, $xp) {
    if ($xp.wifiInstalled) { return }
    $xp.wifiInstalled = true
    Vue.util.defineReactive($xp, 'wifi', this)
  }
}
