export default {
  __isPlugged: null,
  __level: 0,
  __$xp: null,
  __$router: null,
  get level () {
    return this.__level
  },
  get isPlugged () {
    return this.__isPlugged
  },
  setBatteryStatus (status) {
    this.__isPlugged = status.isPlugged
    this.__level = status.level
  },
  startListening (handler) {
    window.addEventListener('batterystatus', handler, false)
  },
  stopListening (handler) {
    window.removeEventListener('batterystatus', handler, false)
  },
  init () {
    this.__$xp.device.isLoan && this.startListening(this.onBatteryStatus.bind(this))
  },
  onBatteryStatus (status) {
    if (this.__$xp.device.platform === 'android') {
      if (this.isPlugged !== null) {
        if (!this.isPlugged && status.isPlugged) {
          this.__$router.replace('/dock')
        } else if (this.isPlugged && !status.isPlugged && this.__$router.currentRoute.path === '/dock') {
          this.__$router.push('/')
        }
      }
      this.setBatteryStatus({ isPlugged: status.isPlugged, level: status.level })
    }
  },
  install (Vue, opts, $xp) {
    if ($xp.batteryInstalled) { return }
    $xp.batteryInstalled = true
    this.__$xp = $xp
    this.__$router = opts.router
    Vue.util.defineReactive($xp, 'battery', this)
  }
}
